export const LoadingIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <style>
        {`.spinner_hzlK{animation:spinner_vc4H .8s linear infinite;animation-delay:-.8s}.spinner_koGT{animation-delay:-.65s}.spinner_YF1u{animation-delay:-.5s}@keyframes spinner_vc4H{0%{y:1px;height:22px}93.75%{y:5px;height:14px;opacity:.2}}`}
      </style>
      <rect className="spinner_hzlK" x="1" y="1" width="6" height="22" />
      <rect
        className="spinner_hzlK spinner_koGT"
        x="9"
        y="1"
        width="6"
        height="22"
      />
      <rect
        className="spinner_hzlK spinner_YF1u"
        x="17"
        y="1"
        width="6"
        height="22"
      />
    </svg>
  );
};

export const FileWithTextIcon = ({ type }: { type?: "csv" | "excel" }) => {
  return (
    <svg
      width="22px"
      height="22px"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {type === "csv" ? (
        <path
          d="M13.5 3.5H14V3.29289L13.8536 3.14645L13.5 3.5ZM10.5 0.5L10.8536 0.146447L10.7071 0H10.5V0.5ZM6.5 6.5V6H6V6.5H6.5ZM6.5 8.5H6V9H6.5V8.5ZM8.5 8.5H9V8H8.5V8.5ZM8.5 10.5V11H9V10.5H8.5ZM10.5 9.5H10V9.70711L10.1464 9.85355L10.5 9.5ZM11.5 10.5L11.1464 10.8536L11.5 11.2071L11.8536 10.8536L11.5 10.5ZM12.5 9.5L12.8536 9.85355L13 9.70711V9.5H12.5ZM2.5 6.5V6H2V6.5H2.5ZM2.5 10.5H2V11H2.5V10.5ZM2 5V1.5H1V5H2ZM13 3.5V5H14V3.5H13ZM2.5 1H10.5V0H2.5V1ZM10.1464 0.853553L13.1464 3.85355L13.8536 3.14645L10.8536 0.146447L10.1464 0.853553ZM2 1.5C2 1.22386 2.22386 1 2.5 1V0C1.67157 0 1 0.671573 1 1.5H2ZM1 12V13.5H2V12H1ZM2.5 15H12.5V14H2.5V15ZM14 13.5V12H13V13.5H14ZM12.5 15C13.3284 15 14 14.3284 14 13.5H13C13 13.7761 12.7761 14 12.5 14V15ZM1 13.5C1 14.3284 1.67157 15 2.5 15V14C2.22386 14 2 13.7761 2 13.5H1ZM9 6H6.5V7H9V6ZM6 6.5V8.5H7V6.5H6ZM6.5 9H8.5V8H6.5V9ZM8 8.5V10.5H9V8.5H8ZM8.5 10H6V11H8.5V10ZM10 6V9.5H11V6H10ZM10.1464 9.85355L11.1464 10.8536L11.8536 10.1464L10.8536 9.14645L10.1464 9.85355ZM11.8536 10.8536L12.8536 9.85355L12.1464 9.14645L11.1464 10.1464L11.8536 10.8536ZM13 9.5V6H12V9.5H13ZM5 6H2.5V7H5V6ZM2 6.5V10.5H3V6.5H2ZM2.5 11H5V10H2.5V11Z"
          fill="#000000"
        />
      ) : (
        <path
          d="M13.5 3.5H14V3.29289L13.8536 3.14645L13.5 3.5ZM10.5 0.5L10.8536 0.146447L10.7071 0H10.5V0.5ZM6.5 10.5H6V11H6.5V10.5ZM4.5 9.5H5V9.29289L4.85355 9.14645L4.5 9.5ZM2.5 7.5H2V7.70711L2.14645 7.85355L2.5 7.5ZM10.5 6.5V6H10V6.5H10.5ZM10.5 8.5H10V9H10.5V8.5ZM12.5 8.5H13V8H12.5V8.5ZM12.5 10.5V11H13V10.5H12.5ZM2.5 9.5L2.14645 9.14645L2 9.29289V9.5H2.5ZM4.5 7.5L4.85355 7.85355L5 7.70711V7.5H4.5ZM2 5V1.5H1V5H2ZM13 3.5V5H14V3.5H13ZM2.5 1H10.5V0H2.5V1ZM10.1464 0.853553L13.1464 3.85355L13.8536 3.14645L10.8536 0.146447L10.1464 0.853553ZM2 1.5C2 1.22386 2.22386 1 2.5 1V0C1.67157 0 1 0.671573 1 1.5H2ZM1 12V13.5H2V12H1ZM2.5 15H12.5V14H2.5V15ZM14 13.5V12H13V13.5H14ZM12.5 15C13.3284 15 14 14.3284 14 13.5H13C13 13.7761 12.7761 14 12.5 14V15ZM1 13.5C1 14.3284 1.67157 15 2.5 15V14C2.22386 14 2 13.7761 2 13.5H1ZM6 6V10.5H7V6H6ZM6.5 11H9V10H6.5V11ZM4 9.5V11H5V9.5H4ZM4.85355 9.14645L2.85355 7.14645L2.14645 7.85355L4.14645 9.85355L4.85355 9.14645ZM3 7.5V6H2V7.5H3ZM13 6H10.5V7H13V6ZM10 6.5V8.5H11V6.5H10ZM10.5 9H12.5V8H10.5V9ZM12 8.5V10.5H13V8.5H12ZM12.5 10H10V11H12.5V10ZM3 11V9.5H2V11H3ZM2.85355 9.85355L4.85355 7.85355L4.14645 7.14645L2.14645 9.14645L2.85355 9.85355ZM5 7.5V6H4V7.5H5Z"
          fill="#000000"
        />
      )}
    </svg>
  );
};
