import { zodResolver } from "@hookform/resolvers/zod";
import * as Sentry from "@sentry/browser";
import { ClipboardCheck, CopyIcon, MessageCircle } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { MenuItem } from "react-pro-sidebar";
import { toast } from "sonner";
import * as z from "zod";
import { useConfigContext } from "~/components/common/config/config-context";
import { Button } from "~/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "~/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/components/ui/form";
import { Input } from "~/components/ui/input";
import { Textarea } from "~/components/ui/textarea";

const formSchema = z.object({
  feedback: z.string().min(1, "Please provide feedback"),
});

export const FeedbackDialog = () => {
  const [isOpen, _setIsOpen] = useState(false);
  const { organizationId, userProfile } = useConfigContext();

  const setIsOpen = (open: boolean) => {
    _setIsOpen(open);
    if (!open) {
      setCopiedItem(false);
      setEventId(null);
      form.reset();
    }
  };

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      feedback: "",
    },
  });

  const [eventId, setEventId] = useState<string | null>(null);
  const onSubmit = (values: z.infer<typeof formSchema>) => {
    console.log(`muly:feedback:onSubmit`, { values });
    const event_id = Sentry.captureMessage("User Feedback");
    const userFeedback = {
      event_id,
      name:
        userProfile?.name ?? userProfile?.email ?? userProfile?.id ?? "unknown",
      email: userProfile?.email,
      comments: values.feedback,
    };
    Sentry.captureUserFeedback(userFeedback);

    // toast({
    //   title: "Feedback was sent, we will contact you shortly",
    // });
    // toast.success(`Feedback was sent, we will contact you shortly`);
    setEventId(event_id);
    // setIsOpen(false);
    // form.reset();
  };

  const [copiedItem, setCopiedItem] = useState<boolean>(false);
  const copyText = async () => {
    setCopiedItem(true);
    if (eventId) {
      await navigator.clipboard.writeText(eventId);
    }
    toast.success(`Copied`);
    setTimeout(() => setCopiedItem(false), 2000); // Reset copied status after 2 seconds
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger className="focus-visible:outline-none">
        <MenuItem icon={<MessageCircle size={24} />} className="text-left">
          Feedback
        </MenuItem>
      </DialogTrigger>
      <DialogContent>
        {eventId ? (
          <>
            <DialogHeader>
              <DialogTitle>Thank you for your feedback!</DialogTitle>
              <DialogDescription>
                Your request has been submitted and assigned the unique ID #
                {eventId}. Please reference this ID if you need to follow up
                with us.
              </DialogDescription>
              <div className="flex items-center gap-2">
                <Input
                  className="w-full cursor-pointer"
                  id="feedback-id"
                  readOnly
                  value={eventId}
                />
                <Button
                  className="items-center gap-2 border"
                  onClick={() => copyText()}
                >
                  {!copiedItem ? (
                    <CopyIcon className="size-4" />
                  ) : (
                    <ClipboardCheck className="size-4" />
                  )}
                  Copy
                </Button>
              </div>
            </DialogHeader>
            <DialogFooter>
              <Button
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                Close
              </Button>
            </DialogFooter>
          </>
        ) : (
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="flex flex-col space-y-8"
            >
              <FormField
                control={form.control}
                name="feedback"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      We're always looking to improve! Share your thoughts on
                      Cube9. Every bit of feedback helps us serve you better.
                    </FormLabel>
                    <FormControl>
                      <Textarea {...field} className="min-h-48" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <Button variant="primary" type="submit">
                Send feedback
              </Button>
            </form>
          </Form>
        )}
      </DialogContent>
    </Dialog>
  );
};
