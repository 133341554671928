import {
  ArrowUpRightFromSquare,
  ChevronRight,
  CircleAlert,
} from "lucide-react";
import Link from "next/link";
import { useRouter } from "next/router";
import { Menu, Sidebar, SubMenu } from "react-pro-sidebar";
import { FeedbackDialog } from "~/components/common/ui/FeedbackDialog";
import MenuItemLink from "~/components/layout/MenuItemLink";
import { Avatar, AvatarFallback, AvatarImage } from "~/components/ui/avatar";
import { cn } from "~/lib/utils";
import type { UserProfile } from "~/server/db-types";
import { api } from "~/utils/api";
import { getAvatarInitials } from "~/utils/avatar-initials";

interface SidebarMenuProps {
  collapsed: boolean;
  organizationId: string;
  userProfile: UserProfile;
  onMenuItemClick: () => void;
}

const menuItemStyles = {
  // @ts-ignore
  button: ({ level, active }) =>
    active && level === 0
      ? {
          backgroundColor: "#2c2b6e",
          marginLeft: "5px",
          marginRight: "20px",
          paddingLeft: "5px",
          paddingRight: "10px",
          borderRadius: "8px",
          color: "white",
          "&:hover": {
            backgroundColor: "#2c2b6e",
          },
        }
      : !active && level === 0
        ? {
            marginLeft: "0px",
            marginRight: "20px",
            paddingLeft: "5px",
            paddingRight: "10px",
            borderLeft: "5px solid hsl(var(--sidebarBackground))",
            color: "white",
            "&:hover": {
              backgroundColor: "hsl(var(--sidebarBackground))",
              borderLeft: "5px solid white",
            },
          }
        : active && (level === 1 || level === 2)
          ? {
              backgroundColor: "#2c2b6e",
              marginLeft: level === 1 ? "15px" : "25px",
              marginRight: "25px",
              paddingLeft: "5px",
              paddingRight: "0px",
              marginTop: "5px",
              color: "white",
              borderRadius: "8px",
              "&:hover": {
                backgroundColor: "#2c2b6e",
              },
            }
          : !active && (level === 1 || level === 2)
            ? {
                marginLeft: level === 1 ? "15px" : "25px",
                marginRight: "25px",
                paddingLeft: "5px",
                paddingRight: "0px",
                marginTop: "5px",
                // borderLeft: "5px solid hsl(var(--sidebarBackground))",
                color: "white",
                "&:hover": {
                  backgroundColor: "hsl(var(--sidebarBackground))",
                  borderLeft: "5px solid white",
                },
              }
            : {},
};

export const SidebarMenu = ({
  organizationId,
  userProfile,
  collapsed,
  onMenuItemClick,
}: SidebarMenuProps) => {
  const router = useRouter();
  // const { theme } = useTheme();

  const isOrgAdmin = userProfile.organizationRole === "MANAGER";
  const isAdmin = userProfile.role === "ADMIN";
  const { data: queryCounts } = api.misc.getNotificationQueryCache.useQuery(
    {
      organizationId,
    },
    {
      placeholderData: (prev) => prev || {},
      refetchOnWindowFocus: false,
    },
  );
  const counts = ["customer", "deal", "product"].map(
    (objectName) =>
      (queryCounts?.[`${objectName}:get-staged-data`] ?? 0) +
      (queryCounts?.[`${objectName}:get-duplicate-data`] ?? 0) +
      (queryCounts?.[`${objectName}:get-missing-data`] ?? 0) +
      (queryCounts?.[`${objectName}:gap-report`] ?? 0) +
      (queryCounts?.[`${objectName}:issues`] ?? 0),
  );

  const dataLabAlert = counts.reduce((acc, count) => acc + count, 0) > 0;
  const customerAlert = counts[0]! > 0;
  const dealAlert = counts[1]! > 0;
  const productAlert = counts[2]! > 0;

  return (
    <Sidebar
      collapsed={collapsed}
      collapsedWidth={`${window.innerWidth < 768 ? 0 : 80}px`}
      className={cn("h-screen [&>div]:bg-sidebarBackground")}
    >
      <Menu
        className={cn(
          "h-full min-h-full bg-sidebarBackground px-0 py-5 text-sidebarText [&>ul>li>a]:!outline-none [&>ul>li>a]:!ring-0 [&>ul]:flex [&>ul]:h-full [&>ul]:flex-col [&>ul]:justify-between",
        )}
        menuItemStyles={menuItemStyles}
      >
        <div>
          <Link href="/" tabIndex={-1}>
            <img
              src="/logo/LogoSidebar.png"
              className="mx-auto mb-5 cursor-pointer"
              width={collapsed ? 40 : 90}
              alt="logo"
            />
          </Link>
          <MenuItemLink
            href="/"
            // icon={<Grid2x2Plus />}
            onClick={onMenuItemClick}
            label="Home"
            className={cn({
              "!w-18 ml-2": collapsed,
            })}
          />
          <MenuItemLink
            href="/arr"
            onClick={onMenuItemClick}
            label="ARR"
            className={cn({
              "!w-18 ml-2": collapsed,
            })}
          />
          <MenuItemLink
            href="/customers"
            onClick={onMenuItemClick}
            label="Customers"
            className={cn({
              "!w-18 ml-2": collapsed,
            })}
          />
          <MenuItemLink
            href="/invoices"
            onClick={onMenuItemClick}
            label="Invoices"
            className={cn({
              "!w-18 ml-2": collapsed,
            })}
          />
          <MenuItemLink
            href="/notifications"
            onClick={onMenuItemClick}
            label="Notifications"
            className={cn({
              "!w-18 ml-2": collapsed,
            })}
          />
          <MenuItemLink
            href="/products"
            onClick={onMenuItemClick}
            label="Products"
            className={cn({
              "!w-18 ml-2": collapsed,
            })}
          />
          <SubMenu
            label={
              <div className="flex items-center gap-2">
                Data Lab{" "}
                {dataLabAlert && (
                  <CircleAlert size={16} className="text-red-500" />
                )}
              </div>
            }
            className={cn(
              "[&>div]:bg-sidebarBackground [&>div]:text-sidebarText",
              {
                "ml-2 [&>a>.ps-submenu-expand-icon]:hidden": collapsed,
              },
            )}
          >
            <MenuItemLink
              href="/data-labs/customers"
              onClick={onMenuItemClick}
              label={
                <div className="flex items-center gap-2">
                  Customers{" "}
                  {customerAlert && (
                    <CircleAlert size={16} className="text-red-500" />
                  )}
                </div>
              }
              className={cn({
                "-ml-2.5 !w-24": collapsed,
              })}
            />
            <MenuItemLink
              href="/data-labs/deals"
              onClick={onMenuItemClick}
              label={
                <div className="flex items-center gap-2">
                  Deals{" "}
                  {dealAlert && (
                    <CircleAlert size={16} className="text-red-500" />
                  )}
                </div>
              }
              className={cn({
                "-ml-2.5 !w-24": collapsed,
              })}
            />
            <MenuItemLink
              href="/data-labs/products"
              onClick={onMenuItemClick}
              label={
                <div className="flex items-center gap-2">
                  Products{" "}
                  {productAlert && (
                    <CircleAlert size={16} className="text-red-500" />
                  )}
                </div>
              }
              className={cn({
                "-ml-2.5 !w-24": collapsed,
              })}
            />
          </SubMenu>
          {isOrgAdmin && (
            <SubMenu
              label="Configuration"
              className={cn(
                "[&>div]:bg-sidebarBackground [&>div]:text-sidebarText",
                {
                  "ml-2 [&>a>.ps-submenu-expand-icon]:hidden": collapsed,
                },
              )}
            >
              <MenuItemLink
                href="/team"
                onClick={onMenuItemClick}
                label="Team"
                className={cn({
                  "-ml-2.5 !w-24": collapsed,
                })}
              />
              <MenuItemLink
                href="/integrations"
                onClick={onMenuItemClick}
                label="Integrations"
                className={cn({
                  "-ml-2.5 !w-24": collapsed,
                })}
              />
              <SubMenu
                label="API"
                className={cn(
                  "[&>div]:bg-sidebarBackground [&>div]:text-sidebarText",
                  {
                    "-ml-2.5 [&>a>.ps-submenu-expand-icon]:hidden": collapsed,
                  },
                )}
              >
                <MenuItemLink
                  href="/configuration/api-keys"
                  onClick={onMenuItemClick}
                  label="API Keys"
                  className={cn({
                    "-ml-2.5 !w-24": collapsed,
                  })}
                />
                <MenuItemLink
                  href="https://app.theneo.io/c7fccff2-6701-40eb-8ca3-7bcccf801727/cube9"
                  externalLink={true}
                  onClick={onMenuItemClick}
                  label={
                    <div className="flex items-center gap-2">
                      Documentation <ArrowUpRightFromSquare size={12} />
                    </div>
                  }
                  className={cn({
                    "-ml-2.5 !w-24": collapsed,
                  })}
                />
              </SubMenu>
              <MenuItemLink
                href="/settings"
                onClick={onMenuItemClick}
                label="Settings"
                className={cn({
                  "-ml-2.5 !w-24": collapsed,
                })}
              />
            </SubMenu>
          )}
          {isAdmin && (
            <>
              <div className="mt-2"></div>
              <SubMenu
                label="Admin"
                className={cn(
                  "[&>div]:bg-sidebarBackground [&>div]:text-sidebarText",
                  {
                    "ml-2 [&>a>.ps-submenu-expand-icon]:hidden": collapsed,
                  },
                )}
              >
                <MenuItemLink
                  href="/admin/misc-operations"
                  activePath="/admin/misc-operations"
                  onClick={onMenuItemClick}
                  label="Misc Operations"
                  className={cn({
                    "-ml-2.5 !w-24": collapsed,
                  })}
                />
                <MenuItemLink
                  href="/admin/test-integrations"
                  activePath="/admin/test-integrations"
                  onClick={onMenuItemClick}
                  label="Test Integrations"
                  className={cn({
                    "-ml-2.5 !w-24": collapsed,
                  })}
                />
              </SubMenu>
              <div className="mt-4"></div>
            </>
          )}
        </div>
        <div>
          <div
            className={cn({
              "[&>button>li>a>.ps-menu-icon]:ml-1 [&>button>li>a>.ps-menu-label]:hidden":
                collapsed,
            })}
          >
            <FeedbackDialog />
          </div>

          <Link href={`/${organizationId}/account`}>
            <div
              className={cn(
                "mx-5 flex flex-row items-center gap-2 rounded-e-3xl p-2",
                { "!bg-[#2c2b6e]": !collapsed },
              )}
            >
              <Avatar
                className={cn("size-11", {
                  "mx-auto -ml-2": collapsed,
                })}
              >
                <AvatarImage src={userProfile.avatarImage ?? undefined} />
                <AvatarFallback className="text-sm text-black">
                  {getAvatarInitials(userProfile.name)}
                </AvatarFallback>
              </Avatar>
              {!collapsed && (
                <div className="flex w-3/4 gap-1">
                  <p className="truncate">
                    {userProfile.name || userProfile.email}
                  </p>
                  <span className="ml-auto">
                    <ChevronRight />
                  </span>
                </div>
              )}
            </div>
          </Link>
        </div>
      </Menu>
    </Sidebar>
  );
};
