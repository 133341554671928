import { AlertTriangle, Check, HelpCircle, Info, XCircle } from "lucide-react";
import type { ReactNode } from "react";
import { ScrollArea } from "~/components/ui/scroll-area";
import { cn } from "~/lib/utils";

interface Props {
  icon?: "warning" | "error" | "info" | "question" | "none";
  title: string;
  text: string;
  children?: ReactNode;
  className?: string;
}

export const ShortMessage = ({
  title,
  text,
  children,
  icon,
  className,
}: Props) => {
  const iconMap = {
    warning: <AlertTriangle className="size-10" />,
    error: <XCircle className="size-10" />,
    info: <Info className="size-10" />,
    question: <HelpCircle className="size-10" />,
    check: <Check className="size-10" />,
    none: null,
  };

  return (
    <div
      className={cn("flex size-full items-center justify-center", className)}
    >
      <div className="flex w-3/5 flex-col gap-2 text-left">
        <div>
          <div className="mx-auto w-fit text-tabs-active">
            {iconMap[icon ?? "check"]}
          </div>
          <h1 className="text-2xl font-bold text-tabs-active">{title}</h1>
        </div>
        <ScrollArea>
          <p className="max-h-80 whitespace-break-spaces font-medium">{text}</p>
        </ScrollArea>
        {children}
      </div>
    </div>
  );
};
