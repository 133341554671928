import type { ReactNode } from "react";
import * as React from "react";
import { forwardRef } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/components/ui/select";
import { cn } from "~/lib/utils";

interface ItemValue {
  value: string;
  label: string | ReactNode;
}

interface Props
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    "value" | "onChange"
  > {
  value: string | null | undefined;
  onChange: (value: string | null) => void;
  choices: readonly ItemValue[];
  placeholder?: string;
  disabled?: boolean;
  renderValue?: (item: ItemValue) => React.ReactNode;
  className?: string;
  contentClassname?: string;
}

export const SelectInput = forwardRef<HTMLButtonElement, Props>(
  (
    { renderValue, disabled, readOnly, className, ...inputProps }: Props,
    ref,
  ) => {
    const {
      placeholder,
      choices,
      value,
      onChange,
      contentClassname,
      ...fieldProps
    } = inputProps;

    return (
      <Select
        onValueChange={(value) => !readOnly && onChange(value)}
        defaultValue={value || undefined}
        value={value || undefined}
        disabled={disabled}
      >
        <SelectTrigger
          ref={ref}
          className={cn({ "pointer-events-none": readOnly }, className)}
        >
          <SelectValue placeholder={placeholder} {...fieldProps} />
        </SelectTrigger>
        <SelectContent className={cn(["max-h-80", contentClassname])}>
          {choices.map((item) => (
            <SelectItem key={item.value} value={item.value}>
              {renderValue ? renderValue(item) : item.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    );
  },
);

SelectInput.displayName = "SelectInput";
