import { BookOpen, PanelLeft } from "lucide-react";
import Link from "next/link";
import { useRouter } from "next/router";
import { Fragment } from "react";
import { SelectInput } from "~/components/common/forms/SelectInput";
import type { BreadCrumbItem } from "~/components/layout/breadcrumb-context";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "~/components/ui/breadcrumb";
import { Button } from "~/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "~/components/ui/tooltip";
import type { UserProfile } from "~/server/db-types";

interface HeaderProps {
  onSidebarToggleClick: () => void;
  userProfile: UserProfile;
  breadcrumb: BreadCrumbItem[];
}

export const Header = ({
  userProfile,
  onSidebarToggleClick,
  breadcrumb,
}: HeaderProps) => {
  const router = useRouter();
  const handleSwitchOrganization = async (organizationId: string | null) => {
    if (!organizationId) return;

    const url = router.pathname.replace("[orgId]", organizationId);
    await router.push(url);
    router.reload();
  };

  return (
    <div className="absolute top-0 flex w-full flex-row items-center gap-2 bg-white p-0 pl-4 pt-1">
      {/* TODO: replace with icon */}
      <Button variant="ghost" onClick={onSidebarToggleClick}>
        <PanelLeft width="20" height="20" />
      </Button>
      <div>
        <Breadcrumb>
          <BreadcrumbList>
            {breadcrumb.map((breadcrumbItem, index) => (
              <Fragment key={index}>
                <BreadcrumbItem>
                  <BreadcrumbLink href={breadcrumbItem.href}>
                    {breadcrumbItem.label}
                  </BreadcrumbLink>
                </BreadcrumbItem>
                {breadcrumb.length - 1 !== index && <BreadcrumbSeparator />}
              </Fragment>
            ))}
          </BreadcrumbList>
        </Breadcrumb>
      </div>
      {userProfile.integrationsWorkProfile !== "NORMAL" && (
        <div className="rounded-full bg-red-500 px-8 py-2 text-sm text-white">
          {userProfile.integrationsWorkProfile}
        </div>
      )}
      <div className="ml-auto flex gap-2">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Link
                target="_blank"
                href="https://docs.cube9.co/"
                className="flex items-center"
              >
                <BookOpen size={14} />
              </Link>
            </TooltipTrigger>
            <TooltipContent>
              <p>Documentation</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
        {userProfile.organizationMember.length > 1 ? (
          <SelectInput
            value={userProfile.organizationId}
            onChange={handleSwitchOrganization}
            choices={userProfile.organizationMember.map((org) => ({
              value: org.id,
              label: org.name,
            }))}
            className="w-fit border-0 shadow-none"
          />
        ) : (
          <span className="pr-4">
            {userProfile?.organizationMember[0]?.name}
          </span>
        )}
      </div>
    </div>
  );
};
