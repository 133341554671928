import { MenuItem } from "react-pro-sidebar";
import Link from "next/link";
import { useRouter } from "next/router";
import type { ReactNode } from "react";

interface Props {
  href?: string;
  label: string | ReactNode;
  icon?: ReactNode;
  activePath?: string;
  onClick?: () => void;
  className?: string;
  externalLink?: boolean;
}

const MenuItemLink = ({
  href,
  label,
  icon,
  activePath,
  onClick,
  className,
  externalLink = false,
}: Props) => {
  const router = useRouter();

  const linkTo = (href: string) => {
    return !externalLink ? (
      <Link href={`/${router.query.orgId}${href}`} />
    ) : (
      <Link target="_blank" href={`${href}`} />
    );
  };

  return (
    <MenuItem
      component={linkTo(href || "")}
      icon={icon}
      active={router.pathname === (activePath ?? `/[orgId]${href}`)}
      onClick={onClick}
      className={className}
    >
      {label}
    </MenuItem>
  );
};

export default MenuItemLink;
